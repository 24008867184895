<template>
  <v-container style="height: 100%; overflow: auto; max-width: 1200px; margin: 0 auto;" :class="`hideScroll ${$vuetify.theme.dark? 'dark' : ''}`">
    <div class="pt-5">
      <v-btn
        fab
        large
        color="primary"
        @click="addFilterDialog = true"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn
            v-if="!selecting"
            fab
              small
              @click="setSelecting(); allSelected = false"
              v-tooltip="$t('filter.batch')"
              class="ml-4 mt-1"
            >
              <v-icon>
                mdi-check
              </v-icon>
            </v-btn>
            <v-btn
            v-else
            fab
              small
              @click="setSelecting"
              v-tooltip="$t('filter.batch_cancel')"
              class="ml-4 mt-1"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
    </div>
    <div class="mt-4 mb-4 pa-0" v-if="selecting">
      <!-- :disabled="selectedItems.length < 1" -->
      <v-btn
        color="red"
        :disabled="selectedItems.length < 1"
        @click="deleting = true"
        v-if="selecting"
        class="ml-1"
      >
        {{ $t('delete') }}
        <v-icon class="ml-1" small>mdi-delete</v-icon>
      </v-btn>
      <!-- :disabled="selectedItems.length < 1" -->
      <v-btn
        v-if="selecting"
        :disabled="selectedItems.length < 1"
        class="ml-1"
        @click="choosing = true"
      >
        {{$t('analysis.add_to_folder')}}
        <v-icon class="ml-1" small>mdi-folder-outline</v-icon>
      </v-btn> 
      <v-btn
      class="ml-1"
      @click="selectAll"
      >
        {{ allSelected ? $t('filter.cancel_select_all') : $t('filter.select_all')}}
      </v-btn>
    </div>
    <v-row class="mt-6">
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="filter in filters"
        :key="'filteritem' + filter.id"
      >
        <filter-item
          :selecting="selecting"
          :filter="filter"
          @toggleSelection="handleSelection($event)"
        ></filter-item>
      </v-col>
    </v-row>
    <div
      v-if="filters.length == 0"
      style="text-align: center;"
    >
      <v-icon size="100">mdi-filter-outline</v-icon>
      <h2>{{$t('filters.no_filters')}}</h2>
      <v-btn
        @click="addFilterDialog = true" 
        class="mt-6" 
        color="primary"
      >
        {{$t('filters.add_filter')}}
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <create-filter
      :loading="saving"
      :show="addFilterDialog"
      @close="addFilterDialog = false"
      @search="search($event)"
    ></create-filter>
    <ConfirmationDialog
      :show="deleting"
      :subText="$t('filter.confirm_batch_delete')"
      btnColor="red"
      :btnText="$t('delete')"
      :text="$t('filter.delete_filter')"
      @decline="deleting = false"
      @accept="acceptDeleting()"
    />
    <ChooseFolder
      :visible="choosing"
      @closed="choosing = false"
      @chosen="addAllToFolder($event)"
      :text="$t('analysis.add_to_folder')"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CreateFilter from '../components/filters/CreateFilter.vue'
import FilterItem from '../components/filters/FilterItem.vue'
import ConfirmationDialog from '@/components/ConfirmationDialog'
import ChooseFolder from '../components/folder/ChooseFolder.vue'
export default {
  components: {
    CreateFilter,
    FilterItem,
    ConfirmationDialog,
    ChooseFolder
  },
  created() {
    this.initFilters()
      .catch(e => this.error(e))
  },
  deforeDestroy() {
    this.clearItems()
  },
  data: () => ({
    addFilterDialog: false,
    saving: false,
    selecting: false,
    deleting: false,
    choosing: false,
    allSelected: false
  }),
  methods: {
    setSelecting() {
      if(this.selecting) this.clearItems()
      this.selecting = !this.selecting
    },
    search(filter) {
      let filterData = {
        ...filter,
        league_id: this.leagueId,
        include_videos: false,
        include_clips: true,
        include_times: false
      }
      // console.log({
      //   ...filter,
      //   league_id: this.leagueId,
      //   include_videos: false,
      //   include_clips: true,
      //   include_times: false
      // })
      if(!filter.save) {
        this.$router.push(`/filter/nosave?filter=${JSON.stringify(filterData)}`)
        return
      }

      this.postFilter(filterData)
        .then(e => {
          this.addFilterDialog = false
          this.success(this.$t('filter.filter_saved'))
          this.$router.push(`/filter/${e.data.id}`)
        })
        .catch(e => this.error(e))
        .finally(() => {this.saving = false})
    },
    acceptDeleting() {
      this.deleteSelectedItems()
      .then(() => {
          this.success(this.$t('filter.filters_deleted'))
        })
        .catch(e => this.error(e))
        .finally(() => {
          this.allSelected = false,
          this.selecting = false
          this.deleting = false
          this.clearItems()
        })
    },
    addAllToFolder(folder) {
      this.batchAddItems(folder.include_times)
      .then(() => {
        let folderName = folder.name
        if (!folderName) folderName = this.$t('library.library')
        this.success(this.$t('filter.filters_added') + folderName + "!")
      })
      .catch(e => this.error(e))
      .finally(() => {
        this.allSelected = false,
        this.selecting = false
        this.choosing = false
        this.clearItems()
      })
    },
    handleSelection(filter) {
      if(this.selectedItems.includes(filter)) this.removeItem(filter)
      else this.addItem(filter)
    },
    selectAll() {
      if (!this.allSelected) {
        this.allSelected = true
        for(let items of this.filters) {
          this.addItem(items)
        }
      }
      else {
        this.allSelected = false
        for(let items of this.filters) {
          this.removeItem(items)
        }
      }
    },
    ...mapActions('filter', [
      'postFilter',
      'initFilters'
    ]),
    ...mapActions('noti', [
      'error',
      'success'
    ]),
    ...mapActions('batch', [
      'clearItems',
      'addItem',
      'removeItem',
      'deleteSelectedItems',
      'batchAddItems'
    ])
  },
  computed: {
    ...mapGetters('filter', [
      'filters'
    ]),
    ...mapGetters('tag', [
      'tag_groups'
    ]),
    ...mapGetters('user', [
      'leagueId'
    ]),
    ...mapGetters('batch', [
      'selectedItems'
    ])
  }
}
</script>

<style scoped>
.dark {
  background-color: #121212;
}
</style>